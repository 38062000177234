<header>
  <div class="container">
    <div class="singnature">
      <h2>{{title}}</h2>
    </div>
    <div class="share-sec">
      <a [href]="linkedIn">
        <fa-icon [icon]="faLinkedin" size="2x"></fa-icon>
      </a>
    </div>
    <a href="assets/cv.pdf" download class="download-sec right">Download CV</a>
    <div class="profile-img">
      <img src="/assets/images/profile-photo.jpg" alt="img">
    </div>
  </div>
</header>
