<ul class="skills-set">

  <li class="individual-skill" *ngFor="let s of skills">
    <div class="skill_title">
      <span>{{s.title}}</span>
    </div>
    <div class="barfiller" [style.border]="'1px solid ' + s.colour">
      <span class="fill" [style.width]="s.width + '%'" [style.background]="s.colour"></span>
    </div>
  </li>

  <!--<li class="individual-skill">
    <div class="skill_title">
      <span>ServiceNow ITSM platform - 4+ Years Experience</span>
    </div>
    <div class="barfiller">
      <span class="fill" style="width:90%"></span>
    </div>
  </li>
  <li class="individual-skill">
    <div class="skill_title">
      <span>Javascrpt - 4+ Years Experience</span>
    </div>
    <div class="barfiller">
      <span class="fill" style="width:90%"></span>
    </div>
  </li>
  <li class="individual-skill">
    <div class="skill_title">
      <span>Web services & Integrations - 3+ Years Experience</span>
    </div>
    <div class="barfiller">
      <span class="fill" style="width:80%"></span>
    </div>
  </li>
  <li class="individual-skill">
    <div class="skill_title">
      <span>Web frameworks, AngularJS & Angular - 3+ Years Experience</span>
    </div>
    <div class="barfiller">
      <span class="fill" style="width:75%"></span>
    </div>
  </li>
  <li class="individual-skill">
    <div class="skill_title">
      <span>Html / CSS - 3+ Years Experience</span>
    </div>
    <div class="barfiller">
      <span class="fill" style="width:70%"></span>
    </div>
  </li>-->
</ul>
