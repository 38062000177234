import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Experience } from "src/app/Models/experience.model";

@Component({
  selector: "app-experiences",
  templateUrl: "./experiences.component.html",
  styleUrls: ["./experiences.component.scss"],
})
export class ExperiencesComponent implements OnInit {
  @Input() experiences: Experience[];

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog() {
    return console.log("Attempting to open dialog");
    this.dialog.open(DialogElementsExampleDialog, {
      width: '800px',
      data: {
        dataKey: {a:2}
      }
    });
  }
}

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
@Component({
  selector: "dialog-elements-example-dialog",
  template: `
    <h1>Dialog with elements</h1>
    <div>
      This dialog showcases the title, close, content and actions elements.
    </div>
    <div>
      <button mat-button mat-dialog-close>Close</button>
    </div>
  `,
})
export class DialogElementsExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    // will log the entire data object
    console.log("Mat data", this.data);
  }
}
