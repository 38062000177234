import { Component, Input, OnInit } from '@angular/core';
import { Skill } from '../Models/skill.model';

@Component({
  selector: 'app-section-skills',
  templateUrl: './section-skills.component.html',
  styleUrls: ['./section-skills.component.scss']
})

export class SectionSkillsComponent implements OnInit {
  @Input() title: String;
  @Input() description: String;
  @Input() image: String;
  @Input() skills: Skill[];

  constructor() { }

  ngOnInit(): void {
  }

}