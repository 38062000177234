<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 ">
        <!--<img src="images/footer-sign.png" alt="sign">-->
      </div>
      <div class="col-md-6 col-sm-6">
        <h3 class="right copy-right">(C) 2020. All Rights. Two Portal Guys.</h3>
      </div>
    </div>
  </div>
</footer>
