import { Component, Input, OnInit } from '@angular/core';
import { Experience } from 'src/app/Models/experience.model';

@Component({
  selector: 'app-section-experience',
  templateUrl: './section-experience.component.html',
  styleUrls: ['./section-experience.component.scss']
})
export class SectionExperienceComponent implements OnInit {
  @Input() title: String;
  @Input() description: String;
  @Input() image: String;
  @Input() experiences: Experience[];

  constructor() { }

  ngOnInit(): void {
    this.image = `url(${this.image})no-repeat`;
  }

}