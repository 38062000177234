import { Component, Input, OnInit } from '@angular/core';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faLinkedin = faLinkedin;
  @Input() title: string;
  @Input() linkedIn: string;

  constructor() { }

  ngOnInit(): void {
  }

}
